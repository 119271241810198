/**
 * @module qms-kit
 */

import { QmsKit } from './QmsKit';

export * from './interfaces';

export { QmsKit };
export default QmsKit;
