var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md4: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-12" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", color: "primary" } },
                        [_c("v-toolbar-title", [_vm._v("Connect to WebGIS")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _vm.loginErrorMessage
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "top-error-message",
                                  attrs: {
                                    label: "",
                                    outline: "",
                                    color: "red"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.loginErrorMessage))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-form",
                            {
                              attrs: { onSubmit: "return false;" },
                              on: { submit: _vm.onGoBtnClick }
                            },
                            [
                              _c("v-switch", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("v-icon", { staticClass: "pr-1" }, [
                                          _vm._v("mdi-cloud")
                                        ]),
                                        _vm._v(
                                          "From NextGIS Web cloud\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.fromCloud,
                                  callback: function($$v) {
                                    _vm.fromCloud = $$v
                                  },
                                  expression: "fromCloud"
                                }
                              }),
                              _vm._v(" "),
                              _vm.fromCloud
                                ? _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "align-center": "",
                                        "justify-space-between": "",
                                        row: "",
                                        "fill-height": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function($event) {
                                              _vm.protocolClick++
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.protocol))]
                                      ),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        ref: "webgis-name",
                                        attrs: {
                                          name: "webgis-name",
                                          label: "WebGIS name",
                                          type: "text"
                                        },
                                        model: {
                                          value: _vm.urlStr,
                                          callback: function($$v) {
                                            _vm.urlStr = $$v
                                          },
                                          expression: "urlStr"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-chip", [
                                        _vm._v("." + _vm._s(_vm.cloudUrl))
                                      ])
                                    ],
                                    1
                                  )
                                : _c("v-text-field", {
                                    ref: "url",
                                    attrs: {
                                      name: "url",
                                      label: "WebGIS URL",
                                      type: "text",
                                      rules: [_vm.rules.validUrl]
                                    },
                                    model: {
                                      value: _vm.urlStr,
                                      callback: function($$v) {
                                        _vm.urlStr = $$v
                                      },
                                      expression: "urlStr"
                                    }
                                  }),
                              _vm._v(" "),
                              _c("v-switch", {
                                attrs: { label: "As guest" },
                                model: {
                                  value: _vm.guest,
                                  callback: function($$v) {
                                    _vm.guest = $$v
                                  },
                                  expression: "guest"
                                }
                              }),
                              _vm._v(" "),
                              !_vm.guest
                                ? _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        ref: "login",
                                        attrs: {
                                          name: "login",
                                          label: "Login",
                                          type: "text"
                                        },
                                        model: {
                                          value: _vm.login,
                                          callback: function($$v) {
                                            _vm.login = $$v
                                          },
                                          expression: "login"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        ref: "password",
                                        attrs: {
                                          name: "password",
                                          label: "Password",
                                          id: "password",
                                          type: "password"
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.isLoading
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", disabled: "" },
                                  on: { click: _vm.onGoBtnClick }
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: { indeterminate: "" }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.isValid
                                  },
                                  on: { click: _vm.onGoBtnClick }
                                },
                                [_vm._v("Go")]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }