import { render, staticRenderFns } from "./ResourcePage.vue?vue&type=template&id=4e73f93e&"
import script from "./ResourcePage.vue?vue&type=script&lang=ts&"
export * from "./ResourcePage.vue?vue&type=script&lang=ts&"
import style0 from "./ResourcePage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!/Users/artemiy/nextgis/nextgisweb_viewer/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileSubTitle } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VNavigationDrawer } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VTab } from 'vuetify/lib'
import { VTabItem } from 'vuetify/lib'
import { VTabs } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {VCard,VCardText,VContainer,VFlex,VIcon,VImg,VLayout,VList,VListTile,VListTileAction,VListTileContent,VListTileSubTitle,VListTileTitle,VNavigationDrawer,VProgressCircular,VSelect,VTab,VTabItem,VTabs,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/Users/artemiy/nextgis/nextgisweb_viewer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('4e73f93e', component.options)
    } else {
      api.reload('4e73f93e', component.options)
    }
    module.hot.accept("./ResourcePage.vue?vue&type=template&id=4e73f93e&", function () {
      api.rerender('4e73f93e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Resource/ResourcePage.vue"
export default component.exports