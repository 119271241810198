var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { background: "#0070c5" },
              attrs: {
                clipped: _vm.$vuetify.breakpoint.lgAndUp,
                open: _vm.open,
                "load-children": _vm.loadChildren,
                dark: "",
                fixed: "",
                app: ""
              },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c("v-text-field", {
                staticClass: "pl-3 pr-3 pt-3",
                attrs: {
                  loading: _vm.searchLoading,
                  solo: "",
                  label: "Search by full name",
                  clearable: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              }),
              _vm._v(" "),
              _vm.items
                ? _c("v-treeview", {
                    staticClass: "pt-3 pb-3",
                    attrs: {
                      "load-children": _vm.fetch,
                      active: _vm.active,
                      items: _vm.items,
                      open: _vm.open,
                      activatable: "",
                      "open-on-click": "",
                      transition: "",
                      "expand-icon": "mdi-chevron-down",
                      "loading-icon": "mdi-loading"
                    },
                    on: {
                      "update:active": function($event) {
                        _vm.active = $event
                      },
                      "update:open": _vm.onOpen
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "span",
                                [
                                  item.icon
                                    ? _c("v-icon", [_vm._v(_vm._s(item.icon))])
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "label",
                          fn: function(ref) {
                            var item = ref.item
                            return [_c("span", [_vm._v(_vm._s(item.name))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1769886734
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-toolbar",
            {
              attrs: {
                "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
                color: "#e5eef7",
                app: "",
                fixed: ""
              }
            },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "ml-0 pl-0" },
                [
                  _c("v-toolbar-side-icon", {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.drawer = !_vm.drawer
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "title ml-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.webGis
                      ? _vm.webGis.meta.systemName || _vm.webGis.id
                      : "loading..."
                  )
                )
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("span", { staticClass: "title mr-2" }, [
                _vm._v(
                  _vm._s(
                    (_vm.webGis && _vm.webGis.auth && _vm.webGis.auth.login) ||
                      "guest"
                  )
                )
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.logout } },
                [_c("v-icon", [_vm._v("mdi-logout")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-content", [_vm.webGis ? _c("router-view") : _vm._e()], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }