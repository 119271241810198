var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "wrapper pa-0 ma-0" },
    [
      _c("div", { attrs: { id: "map" } }),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", absolute: "", "hide-overlay": "" },
          model: {
            value: _vm.selectedFeature,
            callback: function($$v) {
              _vm.selectedFeature = $$v
            },
            expression: "selectedFeature"
          }
        },
        [
          _vm.selectedFeature
            ? _c(
                "div",
                { staticClass: "attribution-container" },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-action",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.cleanSelected($event)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-content",
                            [
                              _vm.selectedFeatures.length > 1
                                ? _c("v-select", {
                                    attrs: { items: _vm.selectedFeatures },
                                    on: { change: _vm.onSelectionChange },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      "Feature#" +
                                                        _vm._s(data.item.id)
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  data.item.layer
                                                    ? _c(
                                                        "v-list-tile-sub-title",
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data.item.layer
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "selection",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      "Feature#" +
                                                        _vm._s(data.item.id)
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  data.item.layer
                                                    ? _c(
                                                        "v-list-tile-sub-title",
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data.item.layer
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      329627868
                                    ),
                                    model: {
                                      value: _vm.featureToSelectModel,
                                      callback: function($$v) {
                                        _vm.featureToSelectModel = $$v
                                      },
                                      expression: "featureToSelectModel"
                                    }
                                  })
                                : _c(
                                    "v-list-tile-title",
                                    {
                                      staticClass:
                                        "attribution-container__header"
                                    },
                                    [
                                      _vm._v(
                                        "Feature#" +
                                          _vm._s(_vm.selectedFeature.id)
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tabs-container-div" },
                    [
                      _c(
                        "v-tabs",
                        {
                          model: {
                            value: _vm.activeTab,
                            callback: function($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab"
                          }
                        },
                        [
                          _vm.selectedFeature.fields
                            ? _c("v-tab", { attrs: { ripple: "" } }, [
                                _vm._v("Fields")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedFeature.extensions.attachment
                            ? _c("v-tab", { attrs: { ripple: "" } }, [
                                _vm._v("Attachment")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedFeature.extensions.description
                            ? _c("v-tab", { attrs: { ripple: "" } }, [
                                _vm._v("Description")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedFeature.fields
                            ? _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                column: "",
                                                "fill-height": ""
                                              }
                                            },
                                            _vm._l(
                                              _vm.selectedFeature.fields,
                                              function(f, i) {
                                                return _c(
                                                  "v-flex",
                                                  { key: i },
                                                  [
                                                    f
                                                      ? _c("v-text-field", {
                                                          attrs: {
                                                            readonly: "",
                                                            value: f,
                                                            label: i
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedFeature.extensions.attachment &&
                          _vm.webGis
                            ? _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    _vm._l(
                                      _vm.selectedFeature.extensions.attachment,
                                      function(a) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: a.id,
                                            attrs: { xs6: "", "d-flex": "" }
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "d-flex",
                                                attrs: { flat: "", tile: "" }
                                              },
                                              [
                                                _c("v-img", {
                                                  staticClass: "grey lighten-2",
                                                  attrs: {
                                                    src: a.src,
                                                    "aspect-ratio": "1"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "placeholder",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  "fill-height":
                                                                    "",
                                                                  "align-center":
                                                                    "",
                                                                  "justify-center":
                                                                    "",
                                                                  "ma-0": ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-progress-circular",
                                                                  {
                                                                    attrs: {
                                                                      indeterminate:
                                                                        "",
                                                                      color:
                                                                        "grey lighten-5"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedFeature.extensions.description
                            ? _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c("v-card-text", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedFeature.extensions
                                              .description
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }